import { DataGrid, GridRowsProp, GridColDef } from '@mui/x-data-grid'
import { Link, useNavigate } from 'react-router-dom'
import { Toolbar } from '@mui/material'
import * as React from 'react'
import { useQuery, gql } from '@apollo/client'
import { getAllStyles, getExplorePage, getModels } from '../../graphql/queries'
import ProgressBar from '../circularProgress/circularProgress'


const columns = [
  { field: 'name', headerName: 'Name', flex: 3 },
  {
    field: 'image',
    headerName: 'Banner',
    flex: 1.5,
    renderCell: (params) => (
      <img
        src={params.value}
        alt="Thumbnail"
        style={{ width: '100%', height: 'auto', maxHeight: '250px', objectFit: 'cover' }}
      />
    ),
  },
]


export default function ModelTable() {
  const navigate = useNavigate()
  const { loading, error, data } = useQuery(getModels)
  if (loading) {
    return <ProgressBar />
  }

  const handleRowClick = (params) => {
    navigate(`/models/${params.id}`)
  }

  if (loading) {
    return <p>Loading...</p>
  }

  if (error) {
    return <p>Error: {error.message}</p>
  }

  const rowsData = data && data.getAllModelsAdmin ? data.getAllModelsAdmin : []

  return (
    <div style={{ width: '100%' }}>
      <Toolbar>
        <div className="d-flex flex-row-reverse w-100">
          <Link to="/styles/create">
            <button className="btn btn-sm btn-dark me-3">Create</button>
          </Link>
        </div>
      </Toolbar>
      <DataGrid
        rowHeight={200}
        rows={rowsData}
        columns={columns}
        checkboxSelection
        onRowClick={handleRowClick}
      />
    </div>
  )
}
