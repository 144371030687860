import { DataGrid } from '@mui/x-data-grid'
import { Link, useNavigate } from 'react-router-dom'
import { Toolbar } from '@mui/material'
import TextField from '@mui/material/TextField'
import * as React from 'react'
import { useQuery } from '@apollo/client'
import { getAllUsers, getAllvideos, getAllSets } from '../../graphql/queries'
import { useState, useEffect } from 'react'
import ProgressBar from '../circularProgress/circularProgress'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Stack from '@mui/material/Stack'
import Pagination from '@mui/material/Pagination'
import LinearProgress from '@mui/material/LinearProgress'

const columns = [
  {
    field: 'createdAt',
    headerName: 'Created At',
    flex: 1,
    minWidth: 150,
    valueGetter: (params) => {
      const date = new Date(params.row.createdAt)
      return date.toLocaleDateString('tr-TR')
    },
  },
  {
    field: 'id',
    headerName: 'ID',
    flex: 2,
    minWidth: 250,
  },
  {
    field: 'name',
    headerName: 'Name',
    flex: 1,
    minWidth: 100,
  },
  {
    field: 'status',
    headerName: 'Status',
    flex: 1,
    minWidth: 100,
  },
]

export default function SetTable() {
  const navigate = useNavigate()
  const [filterEmail, setFilterEmail] = useState('')
  const [currentPage, setCurrentPage] = React.useState(1)
  const [status, setStatus] = useState()

  const { loading, error, data, refetch } = useQuery(getAllSets)


  if (loading) {
    return <ProgressBar />
  }

  const handleRowClick = (params) => {
    navigate(`/sets/${params.id}`)
  }


  console.log('data:', data)
  const rowsData = data && data.getAllSetsAdmin && data.getAllSetsAdmin ? data.getAllSetsAdmin : []


  if (error) {
    return <p>Error: {error.message}</p>
  }

  return (
    <div style={{ width: '100%' }}>
      <Toolbar>
        <FormControl variant="filled" sx={{ m: 1, minWidth: 120 }}>
          <InputLabel id="demo-simple-select-filled-label">Status</InputLabel>
          <Select
            labelId="demo-simple-select-filled-label"
            id="demo-simple-select-filled"
            name="userId"
          >
            <MenuItem value="FAILED">FAILED</MenuItem>
            <MenuItem value="RUNNING">RUNNING</MenuItem>
            <MenuItem value="SUCCEEDED">SUCCEEDED</MenuItem>
            <MenuItem value="PENDING">PENDING</MenuItem>
          </Select>
        </FormControl>
        <div className="d-flex flex-row-reverse w-100">
          <Link to="/credits/create">
            <button className="btn btn-sm btn-dark me-3">Create</button>
          </Link>
        </div>
      </Toolbar>
      <DataGrid
        rowHeight={100}
        rows={rowsData}
        columns={columns}
        pageSize={5}
        onRowClick={handleRowClick}
      />
      {/*      <div className="d-flex justify-content-center">
        <Stack spacing={2}>
          <Pagination
            page={currentPage}
            onChange={(e, value) => handlePagination(e, value)}
            count={data && data.getAllVideos && Math.round(data.getAllVideos.totalCount / 8)}
            variant="outlined"
          />
        </Stack>
      </div>*/}
    </div>
  )
}
