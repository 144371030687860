import * as React from 'react'
import UserTable from './components/userTable/usertable'
import SideMenu from './components/sidemenu/sidemenu'
import CategoryTable from './components/categoryTable/categoryTable'
import SingleUserPage from './pages/singleuserPage'
import SetTable from './components/setTable/setTable'
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from 'react-router-dom'
import LoginPage from './pages/loginPage'
import NavBar from './components/navbar/navBar'
import HomePage from './pages/homePage'
import StyleTable from './components/styleTable/styleTable'
import StylePage from './pages/stylePage'
import CreateStylePage from './pages/createPages/createStyle'
import PromptTable from './components/promptTable/promptTable'
import PromptPage from './pages/promptPage'
import CreatePromptPage from './pages/createPages/createPrompt'
import SingleSetPage from './pages/setPage'
import ModelTable from './components/modelTable/modelTable'
import SingleModelPage from './pages/modelPage'

export default function App() {
  const isLoggedIn = window.localStorage.getItem('token')
  return (
    <Router>
      {isLoggedIn ? (
        <div>
          <div>
            <NavBar />
          </div>
          <div className="d-flex">
            <div className="col-1">
              <SideMenu />
            </div>
            <div className="col-11">
              <Routes>
                <Route path="/users" element={<UserTable />} />
                <Route path="/models" element={<ModelTable />} />
                <Route path="/models/:id" element={<SingleModelPage />} />
                <Route path="/styles" element={<StyleTable />} />
                <Route path="/prompts" element={<PromptTable />} />
                <Route path="/prompts/create" element={<CreatePromptPage />} />
                <Route path="/prompts/:id" element={<PromptPage />} />
                <Route path="/categories" element={<CategoryTable />} />
                <Route path="/styles/create" element={<CreateStylePage />} />
                <Route path="/styles/:id" element={<StylePage />} />
                <Route path="/users/:id" element={<SingleUserPage />} />
                <Route path="/sets/:id" element={<SingleSetPage />} />
                <Route path="/sets" element={<SetTable />} />
                <Route path="/" element={<HomePage />} />
              </Routes>
            </div>
          </div>
        </div>
      ) : (
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route path="*" element={<Navigate to="/login" />} />
        </Routes>
      )}
    </Router>
  )
}
