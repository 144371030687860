import React, { useState } from 'react'
import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import { uploadToS3 } from '../../utils/uploadToS3'
import Alert from '@mui/material/Alert'
import { useMutation, useQuery } from '@apollo/client'
import { createPrompt } from '../../graphql/mutation'
import { getAllStyles } from '../../graphql/queries'
import CircularProgress from '@mui/material/CircularProgress'
import ReactPlayer from 'react-player'

const outPurFormat = [
  { value: 1, name: 'webp' },
  { value: 2, name: 'jpg' },
  { value: 3, name: 'png' },
]

const gender = [
  { value: 1, name: 'MALE' },
  { value: 2, name: 'FEMALE' },
  { value: 3, name: 'OTHER' },
]
const selectAspectRatioData = [
  { value: 1, name: '1:1' },
  { value: 2, name: '16:9' },
  { value: 3, name: '21:9' },
  { value: 4, name: '3:2' },
  { value: 5, name: '2:3' },
  { value: 6, name: '4:5' },
  { value: 7, name: '5:4' },
  { value: 8, name: '3:4' },
  { value: 9, name: '4:3' },
  { value: 10, name: '9:16' },
  { value: 11, name: '9:21' },
]


export default function CreatePromptPage() {
  const [file, setFile] = useState(null)
  const [selectedFileUrl, setSelectedFileUrl] = useState()
  const [video, setVideo] = useState(null)
  const [gif, setGif] = useState(null)
  const [selectedGifUrl, setSelectedGifUrl] = useState()
  const [selectedVideoUrl, setSelectedVideoUrl] = useState()
  const [showAlert, setShowAlert] = useState(false)
  const [progress, setProgress] = useState(false)
  const [prompt, setPrompt] = useState({
    prompt: '',
    banner: '',
    isVisible: true,
    createdAt: '',
    updatedAt: '',
    styleId: '',
    gender: '',
    aspectRatio: '',
    guidanceScale: '',
    loraScale: '',
    numInferenceSteps: '',
    outputFormat: '',
    outputQuality: '',
    numOfOutPuts: '',
  })
  const { loading, error, data } = useQuery(getAllStyles)

  const selectStyleData = data && data.getAllStylesAdmin ? data.getAllStylesAdmin : []
  const handleChange = (e) => {
    const { name, value } = e.target
    setPrompt((prev) => ({ ...prev, [name]: value }))
  }

  const handleBannerFileSelected = (e) => {
    const selectedFile = e.target.files[0]
    setSelectedFileUrl(URL.createObjectURL(selectedFile))
    setFile(selectedFile)
  }


  const handleGifUrl = (e) => {
    const gifUrl = e.target.value
    setSelectedGifUrl(gifUrl)
  }
  console.log(file)
  const [createPromptMutation] = useMutation(createPrompt)
  const handleCreatePrompt = async () => {
    setProgress(true)
    const s3ThumbnailUrl = file ? await uploadToS3(file, 'img') : ""
    console.log("s3ThumbnailUrl:",s3ThumbnailUrl)
    const gpqResponse = await createPromptMutation({
      variables: {
        prompt: prompt.prompt,
        banner: s3ThumbnailUrl,
        isVisible: prompt.isVisible,
        styleId: prompt.styleId,
        gender: prompt.gender,
        aspectRatio: prompt.aspectRatio,
        guidanceScale: Number(prompt.guidanceScale),
        loraScale: Number(prompt.loraScale),
        numInferenceSteps: Number(prompt.numInferenceSteps),
        outputFormat: prompt.outputFormat,
        outputQuality: Number(prompt.outputQuality),
        numOfOutPuts: Number(prompt.numOfOutPuts),
      },
    })
    setProgress(false)
    if (gpqResponse.data) {
      setShowAlert(true)
      setTimeout(() => {
        setShowAlert(false)
      }, 3000)
    }
  }
console.log(prompt)
  return (
    <div className="position-relative">
      {showAlert && (
        <div className="alert-container position-fixed top-0 start-50 translate-middle-x mt-3" style={{ zIndex: 999 }}>
          <Alert severity="success" color="info">
            Style successfully created.
          </Alert>
        </div>
      )}
      {progress && (
        <div className="alert-container position-fixed top-0 start-50 translate-middle-x mt-3" style={{ zIndex: 999 }}>
          <CircularProgress />
        </div>
      )}
      <div className="d-flex flex-column">
        <TextField
          fullWidth
          id="filled-basic"
          label="Prompt"
          variant="filled"
          name="prompt"
          onChange={handleChange}
        />
        <TextField
          fullWidth
          id="filled-basic"
          label="guidanceScale"
          variant="filled"
          name="guidanceScale"
          onChange={handleChange}
        />
        <TextField
          fullWidth
          id="filled-basic"
          label="loraScale"
          variant="filled"
          name="loraScale"
          onChange={handleChange}
        />
        <TextField
          fullWidth
          id="filled-basic"
          label="numInferenceSteps"
          variant="filled"
          name="numInferenceSteps"
          onChange={handleChange}
        />
        <TextField
          fullWidth
          id="filled-basic"
          label="outputQuality"
          variant="filled"
          name="outputQuality"
          onChange={handleChange}
        />
        <TextField
          fullWidth
          id="filled-basic"
          label="numOfOutPuts"
          variant="filled"
          name="numOfOutPuts"
          onChange={handleChange}
        />
        <div className="w-25 mb-3">
          <span>Style</span>
          <FormControl fullWidth>
            <Select
              name="styleId"
              onChange={handleChange}
            >
              <MenuItem value={null}>EMPTY</MenuItem>
              {selectStyleData.map((i) => (

                <MenuItem key={i.id} value={i.id}>{i.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className="w-25 mb-3">
          <span>GENDER</span>
          <FormControl fullWidth>
            <Select
              name="gender"
              onChange={handleChange}
            >
              <MenuItem value={null}>EMPTY</MenuItem>
              {gender.map((i) => (
                <MenuItem key={i.value} value={i.name}>{i.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className="w-25 mb-3">
          <span>aspectRatio</span>
          <FormControl fullWidth>
            <Select
              name="aspectRatio"
              onChange={handleChange}
            >
              <MenuItem value={null}>EMPTY</MenuItem>
              {selectAspectRatioData.map((i) => (
                <MenuItem key={i.value} value={i.name}>{i.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className="w-25 mb-3">
          <span>outputformat</span>
          <FormControl fullWidth>
            <Select
              name="outPutFormat"
              onChange={handleChange}
            >
              <MenuItem value={null}>EMPTY</MenuItem>
              {outPurFormat.map((i) => (

                <MenuItem key={i.value} value={i.name}>{i.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className="w-25 mb-3">
          <TextField
            fullWidth
            id="filled-basic"
            label="Thumbnail Url"
            variant="filled"
            name="thumbnailUrl"
            onChange={handleGifUrl}
          />
        </div>
        <span>Thumbnail Photo</span>
        <div className="mb-3 d-flex flex-column pb-2 pt-2">
          <input
            name="banner"
            type="file"
            onChange={handleBannerFileSelected}
          />
          {selectedFileUrl && <img style={{ width: '25%' }} src={selectedFileUrl} />}
        </div>
        <div className="d-flex justify-content-start">
          <button
            onClick={handleCreatePrompt}
            className="btn btn-dark fs-6"
          >
            SAVE
          </button>
        </div>
      </div>
    </div>
  )
}
