import React from 'react';
import Button from '@mui/material/Button';
import { useNavigate, Link } from 'react-router-dom';

export default function NavBar() {
  const navigate = useNavigate();

  const handleLogOut = () => {
    window.localStorage.removeItem('token');
    navigate('/login');
    window.location.reload();
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-dark justify-content-between align-items-center px-3">
      <Link className="navbar-brand" to="/" style={{ textDecoration: 'none', color: 'white' }}>
        Artify
      </Link>
      <div className="d-flex align-items-center">
        <Button
          onClick={handleLogOut}
          variant="text"
          sx={{ color: 'white', textTransform: 'none', marginRight: 1 }}
        >
          Logout
        </Button>
      </div>
    </nav>
  );
}
