import "./chart.css";
import {BarChart, YAxis, Bar, Legend, Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis } from "recharts";

const Chart = ({ aspect, title, predictionData }) => {

    if (!predictionData) {
        return <div>Loading...</div>;
    }


    const firstSevenItems = predictionData.slice(0, 7).slice().reverse();
    return (
        <div className="chart">
            <div className="title">{title}</div>
            <ResponsiveContainer width="100%" aspect={aspect}>
       {/*         <AreaChart
                    width={730}
                    height={250}
                    data={firstSevenItems}

                >
                    <defs>
                        <linearGradient id="count" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8}/>
                            <stop offset="95%" stopColor="#8884d8" stopOpacity={0}/>
                        </linearGradient>
                    </defs>
                    <XAxis dataKey="date" stroke="gray"/>
                    <CartesianGrid strokeDasharray="3 3" className="chartGrid"/>
                    <Tooltip/>
                    <Area
                        type="monotone"
                        dataKey="count"
                        stroke="#8884d8"
                        fillOpacity={1}
                        fill="url(#count)"
                    />
                </AreaChart>*/}
                <BarChart width={730} height={250} data={firstSevenItems} margin={{top: 20, right: 20, bottom: 20, left: 20}} >
                    <XAxis dataKey="date" />
                    <YAxis dataKey="count" />
                    <Tooltip />
                    <Bar dataKey="count" fill="#8884d8" />
                </BarChart>
            </ResponsiveContainer>
        </div>
    );
};

export default Chart;
