import TextField from '@mui/material/TextField'
import React, { useState, useEffect } from 'react'
import SaveIcon from '@mui/icons-material/Save'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'
import DeleteIcon from '@mui/icons-material/Delete'
import { useParams } from 'react-router-dom'
import { useQuery, useMutation } from '@apollo/client'
import { getExploreVideo, getAllStyles, getStyle } from '../graphql/queries'
import ProgressBar from '../components/circularProgress/circularProgress'
import ReactPlayer from 'react-player'
import './video.css'

import { updateExplore, deleteStyle, updateStyle } from '../graphql/mutation'
import Alert from '@mui/material/Alert'
import { uploadToS3 } from '../utils/uploadToS3'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'

const designType = [
  { value: 1, name: 'SMALL' },
  { value: 2, name: 'LARGE' },
  { value: 3, name: 'BANNER' },
  { value: 4, name: 'MEDIUM' },
]
const selectAspectRatioData = [
  { value: 'WIDESCREEN', name: 'WIDESCREEN' },
  { value: 'PORTRAIT', name: 'PORTRAIT' },
  { value: 'SQUARE', name: 'SQUARE' },
  { value: 'FULL_SCREEN', name: 'FULL SCREEN' },
  { value: 'TABLET', name: 'TABLET' },
  { value: 'ULTRAWIDE', name: 'ULTRAWIDE' },
]


export default function StylePage() {
  const { id } = useParams()
  const [styleData, setStyleData] = useState(null)
  const [message, setMessage] = useState(null)
  const [thumbnail, setThumbnail] = useState(null)
  const [selectedThumbnail, setSelectedThumbnail] = useState(null)
  const [video, setVideo] = useState(null)
  const [selectedVideo, setSelectedVideo] = useState(null)
  const [gif, setGif] = useState(null)
  const [selectedGifUrl, setSelectedGifUrl] = useState()
  const [previewMode, setPreviewMode] = useState(false)
  const [style, setStyle] = useState({
    name: '',
    banner: '',
    createdAt: '',
    designType: '',
    gifUrl: '',
    id: '',
    rowNumber: '',
    updatedAt: '',
    videoUrl: '',
  })
  const { loading, error, data } = useQuery(getStyle, {
    variables: { styleId: id },
    onCompleted: (data) => {
      console.log('data:', data)
      setStyleData(data.getStyle)
    },
  })
  console.log('data:', data)
  useEffect(() => {
    if (styleData) {
      setStyle({
        name: styleData.name,
        banner: styleData.banner,
        createdAt: styleData.createdAt,
        designType: styleData.designType,
        gifUrl: styleData.gifUrl,
        aspectRatio: styleData.aspectRatio,
        rowNumber: styleData.rowNumber,
        updatedAt: styleData.updatedAt,
        videoUrl: styleData.videoUrl,
      })
      setSelectedGifUrl(styleData.gifUrl)
      setSelectedThumbnail(styleData.banner)
      setSelectedVideo(styleData.videoUrl)
    }
  }, [styleData])
  console.log('setSelectedThumbnail:', setSelectedThumbnail)
  const [updateStyleMutation] = useMutation(updateStyle)
  const [deleteStyleMutation] = useMutation(deleteStyle)

  if (loading) {
    return <ProgressBar />
  }

  if (error) {
    return <p>Error: {error.message}</p>
  }

  const handlePreviewClick = () => {
    setPreviewMode(!previewMode) // Toggle preview mode
  }
  const handleChange = (e) => {
    console.log(e)
    const { name, value } = e.target
    setStyle((prev) => ({ ...prev, [name]: value }))
  }
  const handleGifSelected = (e) => {
    const selectedFile = e.target.files[0]
    setSelectedGifUrl(URL.createObjectURL(selectedFile))
    setGif(selectedFile)
  }
  const handleVideoSelected = (e) => {
    const selectedFile = e.target.files[0]
    setSelectedVideo(URL.createObjectURL(selectedFile))
    setVideo(selectedFile)
  }
  const handleThumbnailSelected = (e) => {
    const selectedFile = e.target.files[0]
    setSelectedThumbnail(URL.createObjectURL(selectedFile))
    setThumbnail(selectedFile)
  }
  const handleUpdateStyle = async () => {
    const lastGifUrl = gif ? await uploadToS3(gif, 'gif') : styleData.gifUrl
    const lastVideoUrl = video ? await uploadToS3(video, 'video') : styleData.videoUrl
    const thumbnailUrl = thumbnail ? await uploadToS3(thumbnail, 'img') : styleData.banner
    const gpqResponse = await updateStyleMutation({
      variables: {
        styleId: id,
        name: style.name,
        banner: thumbnailUrl,
        createdAt: style.createdAt,
        designType: style.designType,
        gifUrl: lastGifUrl,
        aspectRatio: style.aspectRatio,
        rowNumber: Number(style.rowNumber),
        updatedAt: style.updatedAt,
        videoUrl: lastVideoUrl,
      },
    })
    if (gpqResponse.data) {
      setMessage('Explore successfully updated.')
      setTimeout(() => {
        setMessage(null)
      }, 3000)
    } else {
      setMessage('Error')
      setTimeout(() => {
        setMessage(null)
      }, 3000)
    }
  }
  const handleDeleteStyle = async () => {
    const gpqResponse = await deleteStyleMutation({
      variables: {
        styleId: id,
      },
    })
    if (gpqResponse.data) {
      setMessage('Explore successfully deleted..')
      setTimeout(() => {
        setMessage(null)
      }, 3000)
    } else {
      setMessage('Error')
      setTimeout(() => {
        setMessage(null)
      }, 3000)
    }
  }
  return (
    <>
      {
        message &&
        <div className="fixed-top">
          <Alert severity="success" color="info">
            {message}
          </Alert>
        </div>
      }
      <div className="container mt-4">

        <div className="row justify-content-center">
          <div className="col-md-8">
            <div className="card">
              <div className="card-body">
                <TextField disabled fullWidth id="filled-disabled" label="ID" variant="filled"
                           value={styleData ? styleData.id : ''} />
                <TextField disabled fullWidth id="filled-disabled" label="Created at" variant="filled"
                           value={styleData ? styleData.createdAt : ''} />
                <TextField onChange={handleChange} name="name" fullWidth id="filled-disabled" label="Name"
                           variant="filled"
                           value={style.name} />
                <div className="w-25 mb-3">
                  <span>Design Type</span>
                  <FormControl fullWidth>
                    <Select
                      name="designType"
                      onChange={handleChange}
                      value={style.designType}
                    >
                      <MenuItem value={null}>EMPTY</MenuItem>
                      {
                        designType.map(i => (
                          <MenuItem value={i.name}>{i.name}</MenuItem>
                        ))

                      }

                    </Select>
                  </FormControl>
                </div>
                <TextField onChange={handleChange} name="rowNumber" fullWidth id="filled-disabled" label="rowNumber"
                           variant="filled"
                           value={style.rowNumber} />
                <div>
                  <div>
                    {
                      style && style.banner && (
                        <div>
                          <img
                            className="img-thumbnail"
                            src={style ? selectedThumbnail : ''}
                            alt="Video Thumbnail"
                          />
                          <input
                            name="banner"
                            type="file"
                            onChange={handleThumbnailSelected}
                          />
                          <div />
                        </div>
                      )
                    }
                  </div>
                </div>
                {style && style.videoUrl && (
                  <div className="d-flex justify-content-center mt-3">
                    <div className="embed-responsive embed-responsive-16by9">
                      <ReactPlayer className="embed-responsive-item" url={style ? selectedVideo : ''} controls
                                   playing />
                    </div>
                    <input
                      name="gif"
                      type="file"
                      onChange={handleVideoSelected}

                    />
                  </div>
                )}
              </div>
              <div className=" d-block">
                {
                  styleData && styleData.gifUrl && (
                    <div>

                      <img className="img-thumbnail w-50" src={styleData ? selectedGifUrl : ''}
                           alt="gifThumbnail" />
                      <input
                        name="gif"
                        type="file"
                        onChange={handleGifSelected}

                      />
                    </div>
                  )
                }
              </div>
              <div className="card-footer d-flex justify-content-between bg-light">
                <button onClick={handleUpdateStyle} className="btn btn-primary"><SaveIcon /> Save</button>
                <button onClick={handleDeleteStyle} className="btn btn-danger"><DeleteIcon /> Delete</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>

  )
}
