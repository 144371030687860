import { gql } from '@apollo/client'

export const createOneCredit = gql`
    mutation CreateOneCredit($date: String!, $userId: String!, $amount: Float!) {
        createOneCredit(date: $date, userId: $userId, amount: $amount) {
            amount
            id
        }
    }
`

export const updateUser = gql`
    mutation UpdateUser($userId: String!, $email: String, $role: Role, $isPremium: Boolean) {
        updateUser(userId: $userId, email: $email, role: $role, isPremium: $isPremium) {
            id
        }
    }
`

export const Login = gql`
    mutation Login($email: String!, $password: String!) {
        login(email: $email, password: $password) {
            user {
                id
            }
            token
        }
    }
`
export const createExplore = gql`
    mutation CreateExplore($gif: String, $url: String, $thumbnailUrl: String, $aspectRatio: AspectRatioEnum, $style: ArtStyle, $motionScore: MotionScoreEnum, $categoryId: String, $prompt: String) {
        createExplore(gif: $gif, url: $url, thumbnailUrl: $thumbnailUrl, aspectRatio: $aspectRatio, style: $style, motionScore: $motionScore, categoryId: $categoryId, prompt: $prompt) {
            id
            url
            prompt
            thumbnailUrl
            motionScore
            style
            createdAt
            updatedAt
            aspectRatio
            gif
            categoryId
        }
    }
`
export const updateExplore = gql`
    mutation UpdateExplore($exploreId: String, $categoryId: String, $prompt: String, $motionScore: MotionScoreEnum, $style: ArtStyle, $aspectRatio: AspectRatioEnum, $thumbnailUrl: String, $gif: String, $url: String) {
        updateExplore(exploreId: $exploreId, categoryId: $categoryId, prompt: $prompt, motionScore: $motionScore, style: $style, aspectRatio: $aspectRatio, thumbnailUrl: $thumbnailUrl, gif: $gif, url: $url) {
            id
            url
            prompt
            thumbnailUrl
            motionScore
            style
            createdAt
            updatedAt
            aspectRatio
            gif
            categoryId
        }
    }
`
export const createCategory = gql`
    mutation CreateCategory($model: ModelEnum, $prompt: [String!], $gif: String, $description: String, $thumbnailUrl: String, $video: String, $categoryType: CategoryType, $isExplore: Boolean, $name: String) {
        createCategory(model: $model, prompt: $prompt, gif: $gif, description: $description, thumbnailUrl: $thumbnailUrl, video: $video, categoryType: $categoryType, isExplore: $isExplore, name: $name) {
            createdAt
            description
            gif
            id
            isExplore
            model
            name
            prompt
            thumbnail
            type
            updatedAt
            video
        }
    }
`

export const updateCategory = gql`
    mutation UpdateCategory($categoryId: String, $isExplore: Boolean, $categoryType: CategoryType, $name: String, $video: String, $thumbnailUrl: String, $description: String, $gif: String, $prompt: [String!], $model: ModelEnum) {
        updateCategory(categoryId: $categoryId, isExplore: $isExplore, categoryType: $categoryType, name: $name, video: $video, thumbnailUrl: $thumbnailUrl, description: $description, gif: $gif, prompt: $prompt, model: $model) {
            id
            name
            video
            gif
            thumbnail
            description
            prompt
            isExplore
            model
            type
            createdAt
            updatedAt
        }
    }
`
export const updateStyle = gql`
    mutation UpdateStyle($designType: StyleDesignEnum!, $rowNumber: Float!, $banner: String!, $name: String!, $styleId: String!, $videoUrl: String, $gifUrl: String) {
        updateStyle(designType: $designType, rowNumber: $rowNumber, banner: $banner, name: $name, styleId: $styleId, videoUrl: $videoUrl, gifUrl: $gifUrl) {
            id
        }
    }
`
export const createStyle = gql`
    mutation CreateStyle($designType: StyleDesignEnum!, $rowNumber: Float!, $banner: String!, $name: String!) {
        createStyle(designType: $designType, rowNumber: $rowNumber, banner: $banner, name: $name) {
            id
        }
    }
`
export const createPrompt = gql`
    mutation CreatePrompt($outputQuality: Float!, $outputFormat: String!, $numInferenceSteps: Float!, $loraScale: Float!, $guidanceScale: Float!, $gender: Gender!, $numOfOutPuts: Float!, $aspectRatio: String!, $styleId: String!, $isVisible: Boolean!, $prompt: String!, $banner: String) {
        createPrompt(outputQuality: $outputQuality, outputFormat: $outputFormat, numInferenceSteps: $numInferenceSteps, loraScale: $loraScale, guidanceScale: $guidanceScale, gender: $gender, numOfOutPuts: $numOfOutPuts, aspectRatio: $aspectRatio, styleId: $styleId, isVisible: $isVisible, prompt: $prompt, banner: $banner) {
            id
        }
    }
`
export const updatePrompt = gql`
    mutation UpdatePrompt($outputQuality: Float!, $outputFormat: String!, $numInferenceSteps: Float!, $loraScale: Float!, $guidanceScale: Float!, $gender: Gender!, $numOfOutPuts: Float!, $aspectRatio: String!, $styleId: String!, $isVisible: Boolean!, $prompt: String!, $promptId: String!, $banner: String) {
        updatePrompt(outputQuality: $outputQuality, outputFormat: $outputFormat, numInferenceSteps: $numInferenceSteps, loraScale: $loraScale, guidanceScale: $guidanceScale, gender: $gender, numOfOutPuts: $numOfOutPuts, aspectRatio: $aspectRatio, styleId: $styleId, isVisible: $isVisible, prompt: $prompt, promptId: $promptId, banner: $banner) {
            id
        }
    }
`
export const deleteStyle = gql`
    mutation DeleteStyle($styleId: String!) {
        deleteStyle(styleId: $styleId)
    }
`
export const deletePrompt = gql`
    mutation DeletePrompt($promptId: String!) {
        deletePrompt(promptId: $promptId)
    }
`