import TextField from '@mui/material/TextField'
import React, { useState, useEffect } from 'react'
import SaveIcon from '@mui/icons-material/Save'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'
import DeleteIcon from '@mui/icons-material/Delete'
import { useParams } from 'react-router-dom'
import { useQuery, useMutation } from '@apollo/client'
import { getExploreVideo, getAllStyles, getPrompt } from '../graphql/queries'
import ProgressBar from '../components/circularProgress/circularProgress'
import ReactPlayer from 'react-player'
import './video.css'

import { deletePrompt, updatePrompt, updateStyle } from '../graphql/mutation'
import Alert from '@mui/material/Alert'
import { uploadToS3 } from '../utils/uploadToS3'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'

const gender = [
  { value: 1, name: 'MALE' },
  { value: 2, name: 'FEMALE' },
  { value: 3, name: 'OTHER' },
]
const selectAspectRatioData = [
  { value: 1, name: '1:1' },
  { value: 2, name: '16:9' },
  { value: 3, name: '21:9' },
  { value: 4, name: '3:2' },
  { value: 5, name: '2:3' },
  { value: 6, name: '4:5' },
  { value: 7, name: '5:4' },
  { value: 8, name: '3:4' },
  { value: 9, name: '4:3' },
  { value: 10, name: '9:16' },
  { value: 11, name: '9:21' },
]


export default function PromptPage() {
  const { id } = useParams()
  const [promptData, setPromptData] = useState(null)
  const [message, setMessage] = useState(null)
  const [thumbnail, setThumbnail] = useState(null)
  const [selectedThumbnail, setSelectedThumbnail] = useState(null)
  const [previewMode, setPreviewMode] = useState(false)
  const [prompt, setPrompt] = useState({
    prompt: '',
    banner: '',
    isVisible: '',
    createdAt: '',
    updatedAt: '',
    styleId: '',
    gender: '',
    aspectRatio: '',
    guidanceScale: '',
    loraScale: '',
    numInferenceSteps: '',
    outputFormat: '',
    outputQuality: '',
    numOfOutPuts: '',
  })
  const { loading, error, data } = useQuery(getPrompt, {
    variables: { promptId: id },
    onCompleted: (data) => {
      setPromptData(data.getPrompt)
    },
  })
  const { loading: styleLoading, error: styleError, data: styleData } = useQuery(getAllStyles)
  const selectStyleData = styleData && styleData.getAllStylesAdmin ? styleData.getAllStylesAdmin : []
  console.log(selectStyleData)
  useEffect(() => {
    if (promptData) {
      setPrompt({
        prompt: promptData.prompt,
        banner: promptData.banner,
        isVisible: promptData.isVisible,
        createdAt: promptData.createdAt,
        updatedAt: promptData.updatedAt,
        gender: promptData.gender,
        aspectRatio: promptData.aspectRatio,
        styleId: promptData.Style.id,
        guidanceScale: promptData.guidanceScale,
        loraScale: promptData.loraScale,
        numInferenceSteps: promptData.numInferenceSteps,
        outputFormat: promptData.outputFormat,
        outputQuality: promptData.outputQuality,
        numOfOutPuts: promptData.numOfOutPuts,
      })
      console.log(promptData)
      setSelectedThumbnail(promptData.banner)
    }
  }, [promptData])
  const [updatePromptMutation] = useMutation(updatePrompt)
  const [deletePromptMutation] = useMutation(deletePrompt)

  if (loading) {
    return <ProgressBar />
  }

  if (error) {
    return <p>Error: {error.message}</p>
  }
  const handleChange = (e) => {
    console.log(e)
    const { name, value } = e.target
    setPrompt((prev) => ({ ...prev, [name]: value }))
  }
  const handleThumbnailSelected = (e) => {
    const selectedFile = e.target.files[0]
    setSelectedThumbnail(URL.createObjectURL(selectedFile))
    setThumbnail(selectedFile)
  }
  console.log(prompt.styleId)
  const handleUpdatePrompt = async () => {
    const thumbnailUrl = thumbnail ? await uploadToS3(thumbnail, 'img') : promptData.banner
    const gpqResponse = await updatePromptMutation({
      variables: {
        promptId: id,
        prompt: prompt.prompt,
        banner: thumbnailUrl,
        isVisible: prompt.isVisible,
        styleId: prompt.styleId,
        gender: prompt.gender,
        aspectRatio: prompt.aspectRatio,
        guidanceScale: Number( prompt.guidanceScale),
        loraScale: Number(prompt.loraScale),
        numInferenceSteps: Number(prompt.numInferenceSteps),
        outputFormat: prompt.outputFormat,
        outputQuality: Number(prompt.outputQuality),
        numOfOutPuts:Number(prompt.numOfOutPuts),
      },
    })
    if (gpqResponse.data) {
      setMessage('Explore successfully updated.')
      setTimeout(() => {
        setMessage(null)
      }, 3000)
    } else {
      setMessage('Error')
      setTimeout(() => {
        setMessage(null)
      }, 3000)
    }
  }
  const handleDeletePrompt = async () => {
    const gpqResponse = await deletePromptMutation({
      variables: {
        promptId: id,
      },
    })
    if (gpqResponse.data) {
      setMessage('Explore successfully deleted..')
      setTimeout(() => {
        setMessage(null)
      }, 3000)
    } else {
      setMessage('Error')
      setTimeout(() => {
        setMessage(null)
      }, 3000)
    }
  }
  return (
    <>
      {
        message &&
        <div className="fixed-top">
          <Alert severity="success" color="info">
            {message}
          </Alert>
        </div>
      }
      <div className="container mt-4">

        <div className="row justify-content-center">
          <div className="col-md-8">
            <div className="card">
              <div className="card-body">
                <TextField disabled fullWidth id="filled-disabled" label="ID" variant="filled"
                           value={promptData ? promptData.id : ''} />
                <TextField disabled fullWidth id="filled-disabled" label="Created at" variant="filled"
                           value={promptData ? promptData.createdAt : ''} />
                <TextField onChange={handleChange} name="prompt" fullWidth id="filled-disabled" label="Prompt"
                           variant="filled"
                           value={prompt.prompt} />
                <TextField onChange={handleChange} name="numOfOutPuts" fullWidth id="filled-disabled"
                           label="numOfOutPuts"
                           variant="filled"
                           value={prompt.numOfOutPuts} />
                <TextField onChange={handleChange} name="numInferenceSteps" fullWidth id="filled-disabled"
                           label="numInferenceSteps"
                           variant="filled"
                           value={prompt.numInferenceSteps} />
                <TextField onChange={handleChange} name="guidanceScale" fullWidth id="filled-disabled"
                           label="guidanceScale"
                           variant="filled"
                           value={prompt.guidanceScale} />
                <TextField onChange={handleChange} name="loraScale" fullWidth id="filled-disabled" label="loraScale"
                           variant="filled"
                           value={prompt.loraScale} />
                <div className="w-25 mb-3">
                  <span>Style</span>
                  <FormControl fullWidth>
                    <Select
                      name="styleId"
                      onChange={handleChange}
                      value={prompt.styleId}
                    >
                      <MenuItem value={null}>EMPTY</MenuItem>
                      {selectStyleData.map((i) => (

                        <MenuItem key={i.id} value={i.id}>{i.name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                s
                <div className="w-25 mb-3">
                  <span>GENDER</span>
                  <FormControl fullWidth>
                    <Select
                      name="gender"
                      onChange={handleChange}
                      value={prompt.gender}
                    >
                      <MenuItem value={null}>EMPTY</MenuItem>
                      {
                        gender.map(i => (
                          <MenuItem value={i.name}>{i.name}</MenuItem>
                        ))

                      }

                    </Select>
                  </FormControl>
                </div>
                <div className="w-25 mb-3">
                  <span>Aspect Ratio</span>
                  <FormControl fullWidth>
                    <Select
                      name="aspectRatio"
                      onChange={handleChange}
                      value={prompt.aspectRatio}
                    >
                      <MenuItem value={null}>EMPTY</MenuItem>
                      {
                        selectAspectRatioData.map(i => (
                          <MenuItem value={i.name}>{i.name}</MenuItem>
                        ))

                      }

                    </Select>
                  </FormControl>
                </div>
                <TextField onChange={handleChange} name="gender" fullWidth id="filled-disabled" label="rowNumber"
                           variant="filled"
                           value={prompt.gender} />
                <div>
                  <div>
                    {
                      prompt && prompt.banner && (
                        <div>
                          <img
                            className="img-thumbnail"
                            src={prompt ? selectedThumbnail : ''}
                            alt="Video Thumbnail"
                          />
                          <input
                            name="banner"
                            type="file"
                            onChange={handleThumbnailSelected}
                          />
                          <div />
                        </div>
                      )
                    }
                  </div>
                </div>
              </div>
              <div className="card-footer d-flex justify-content-between bg-light">
                <button onClick={handleUpdatePrompt} className="btn btn-primary"><SaveIcon /> Save</button>
                <button onClick={handleDeletePrompt} className="btn btn-danger"><DeleteIcon /> Delete</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>

  )
}
