import React from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { getModelData } from '../graphql/queries'
import ProgressBar from '../components/circularProgress/circularProgress'

export default function SingleModelPage() {
  const { id } = useParams()
  const { loading, data } = useQuery(getModelData, {
    variables: { modelId: id },
  })

  if (loading) {
    return <ProgressBar />
  }

  const setData = data && data.getModel ? data.getModel : null

  if (!setData) {
    return <div>No Data Available</div>
  }

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60)
    const remainingSeconds = (seconds % 60).toFixed(2)
    return `${minutes} min ${remainingSeconds} sec`
  }

  return (
    <div className="card p-4">
      <div className="row mb-4">
        <div className="col-md-6">
          <div className="d-flex flex-column mb-3">
            <span className="text-muted h6">Name</span>
            <span>{setData.name}</span>
          </div>
        </div>
        <div className="col-md-6">
          <div className="d-flex flex-column mb-3">
            <span className="text-muted h6">User ID</span>
            <span>{setData.userId}</span>
          </div>
        </div>
        <div className="col-md-6">
          <div className="d-flex flex-column mb-3">
            <span className="text-muted h6">Status</span>
            <span>{setData.status}</span>
          </div>
        </div>
        <div className="col-md-6">
          <div className="d-flex flex-column mb-3">
            <span className="text-muted h6">Predict Time</span>
            <span>{formatTime(setData.predict_time)}</span>
          </div>
        </div>
        <div className="col-md-6">
          <div className="d-flex flex-column mb-3">
            <span className="text-muted h6">Created At</span>
            <span>{new Date(setData.createdAt).toLocaleString()}</span>
          </div>
        </div>
        <div className="col-md-6">
          <div className="d-flex flex-column mb-3">
            <span className="text-muted h6">Updated At</span>
            <span>{new Date(setData.updatedAt).toLocaleString()}</span>
          </div>
        </div>
        <div className="col-md-6">
          <div className="d-flex flex-column mb-3">
            <span className="text-muted h6">Instance Data</span>
            <a href={setData.instanceData} target="_blank" rel="noopener noreferrer">
              Download
            </a>
          </div>
        </div>
      </div>

      {setData.image && (
        <div className="text-center mb-4">
          <img className="img-fluid img-thumbnail" src={setData.image} alt="Model Image" />
        </div>
      )}
    </div>
  )
}
