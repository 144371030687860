import * as React from 'react'
import Widget from '../components/widget/widget'
import Chart from '../components/chart/chart'
import { useQuery } from '@apollo/client'
import {dashboardData} from '../graphql/queries'
import ProgressBar from "../components/circularProgress/circularProgress";
export default function HomePage() {
  const { loading, data } = useQuery(dashboardData)
  const rowData = data && data.dashboardData ? data.dashboardData : []

  if (loading) {
    return <ProgressBar />
  }
  console.log(rowData)
const dailyVideo = rowData && rowData.dailyVideoCount
const reversedArray =  dailyVideo ? dailyVideo.slice().reverse() : []
  return (
    <div>
      <div className="d-flex">
        <div className="w-25 me-3">
          <Widget type="users"
          amount={rowData.totalUser}
          />
        </div>
        <div className="w-25 me-3">
          <Widget type="models"
          amount={rowData.activeSubscriptions}
          />
        </div>

        <div className="w-25 me-3">
          <Widget
            type="predictions"
            amount={rowData.totalVideo}

          />
        </div>

        <div className="w-25 me-3">
          <Widget type="subs"
          amount={rowData.modelCount}
          />
        </div>
      </div>
      <div>
        <Chart
          title="Weekly Prediction Count"
          aspect={2 / 1}
          predictionData={reversedArray}
        />
      </div>
    </div>
  )
}
