import React, { useState } from 'react'
import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import { uploadToS3 } from '../../utils/uploadToS3'
import Alert from '@mui/material/Alert'
import { useMutation } from '@apollo/client'
import { createStyle } from '../../graphql/mutation'
import CircularProgress from '@mui/material/CircularProgress'
import ReactPlayer from 'react-player'

const designType = [
  { value: 1, name: 'SMALL' },
  { value: 2, name: 'LARGE' },
  { value: 3, name: 'BANNER' },
  { value: 4, name: 'MEDIUM' },
]

export default function CreateStylePage() {
  const [file, setFile] = useState(null)
  const [selectedFileUrl, setSelectedFileUrl] = useState()
  const [video, setVideo] = useState(null)
  const [gif, setGif] = useState(null)
  const [selectedGifUrl, setSelectedGifUrl] = useState()
  const [selectedVideoUrl, setSelectedVideoUrl] = useState()
  const [showAlert, setShowAlert] = useState(false)
  const [progress, setProgress] = useState(false)
  const [style, setStyle] = useState({
    name: '',
    description: '',
    banner: '',
    designType: '',
    gifUrl: '',
    rowNumber: '',
    videoUrl: '',
  })

  const handleChange = (e) => {
    const { name, value } = e.target
    setStyle((prev) => ({ ...prev, [name]: value }))
  }

  const handleBannerFileSelected = (e) => {
    const selectedFile = e.target.files[0]
    setSelectedFileUrl(URL.createObjectURL(selectedFile))
    setFile(selectedFile)
  }

  const handleVideoSelected = (e) => {
    const selectedFile = e.target.files[0]
    setSelectedVideoUrl(URL.createObjectURL(selectedFile))
    setVideo(selectedFile)
  }

  const handleGifSelected = (e) => {
    const selectedFile = e.target.files[0]
    setSelectedGifUrl(URL.createObjectURL(selectedFile))
    setGif(selectedFile)
  }

  const handleVideoUrl = (e) => {
    const videoUrl = e.target.value
    setSelectedVideoUrl(videoUrl)
  }

  const handleGifUrl = (e) => {
    const gifUrl = e.target.value
    setSelectedGifUrl(gifUrl)
  }

  const [createStyleMutation] = useMutation(createStyle)
  const handleCreateStyle = async () => {
    setProgress(true)
    const s3VideoUrl = video ? await uploadToS3(video) : selectedVideoUrl
    const s3ThumbnailUrl = file ? await uploadToS3(file, 'img') : selectedFileUrl
    const s3GifUrl = gif ? await uploadToS3(gif, 'gif') : selectedGifUrl
    const gpqResponse = await createStyleMutation({
      variables: {
        name: style.name,
        banner: s3ThumbnailUrl,
        designType: style.designType,
        gifUrl: s3GifUrl,
        aspectRatio: style.aspectRatio,
        rowNumber: Number(style.rowNumber),
        videoUrl: s3VideoUrl,
      },
    })
    setProgress(false)
    if (gpqResponse.data) {
      setShowAlert(true)
      setTimeout(() => {
        setShowAlert(false)
      }, 3000)
    }
  }

  return (
    <div className="position-relative">
      {showAlert && (
        <div className="alert-container position-fixed top-0 start-50 translate-middle-x mt-3" style={{ zIndex: 999 }}>
          <Alert severity="success" color="info">
            Style successfully created.
          </Alert>
        </div>
      )}
      {progress && (
        <div className="alert-container position-fixed top-0 start-50 translate-middle-x mt-3" style={{ zIndex: 999 }}>
          <CircularProgress />
        </div>
      )}
      <div className="d-flex flex-column">
        <TextField
          fullWidth
          id="filled-basic"
          label="Style Name"
          variant="filled"
          name="name"
          onChange={handleChange}
        />
        <TextField
          fullWidth
          id="filled-basic"
          label="Description"
          variant="filled"
          name="description"
          onChange={handleChange}
        />
        <TextField
          fullWidth
          id="filled-basic"
          label="Row Number"
          variant="filled"
          name="rowNumber"
          onChange={handleChange}
        />
        <div className="w-25 mb-3">
          <span>Design Type</span>
          <FormControl fullWidth>
            <Select
              name="designType"
              onChange={handleChange}
            >
              {designType.map((i) => (
                <MenuItem key={i.value} value={i.name}>{i.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className="w-25 mb-3">
          <TextField
            fullWidth
            id="filled-basic"
            label="Video Url"
            variant="filled"
            name="videoUrl"
            onChange={handleVideoUrl}
          />
        </div>
        <div className="w-25 mb-3">
          <TextField
            fullWidth
            id="filled-basic"
            label="Thumbnail Url"
            variant="filled"
            name="thumbnailUrl"
            onChange={handleGifUrl}
          />
        </div>
        <div className="w-25 mb-3">
          <TextField
            fullWidth
            id="filled-basic"
            label="Gif Url"
            variant="filled"
            name="gifUrl"
            onChange={handleGifUrl}
          />
        </div>
        <span>Thumbnail Photo</span>
        <div className="mb-3 d-flex flex-column pb-2 pt-2">
          <input
            name="banner"
            type="file"
            onChange={handleBannerFileSelected}
          />
          {selectedFileUrl && <img style={{ width: '25%' }} src={selectedFileUrl} />}
        </div>
        <span>Video</span>
        <div className="mb-3 d-flex flex-column pb-2 pt-2">
          <input
            name="banner"
            type="file"
            onChange={handleVideoSelected}
          />
          {selectedVideoUrl && (
            <div className="d-flex justify-content-start mt-3">
              <ReactPlayer url={selectedVideoUrl} controls playing />
            </div>
          )}
        </div>
        <span>Gif</span>
        <div className="mb-3 d-flex flex-column pb-2 pt-2">
          <input
            name="banner"
            type="file"
            onChange={handleGifSelected}
          />
          {selectedGifUrl && <img style={{ width: '25%' }} src={selectedGifUrl} />}
        </div>
        <div className="d-flex justify-content-start">
          <button
            onClick={handleCreateStyle}
            className="btn btn-dark fs-6"
          >
            SAVE
          </button>
        </div>
      </div>
    </div>
  )
}
