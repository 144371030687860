import TextField from '@mui/material/TextField'
import * as React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useMutation, useQuery } from '@apollo/client'
import { getUser, getUserCreditHistory, dashboardUserVideos } from '../graphql/queries'
import { updateUser } from '../graphql/mutation'
import ProgressBar from '../components/circularProgress/circularProgress'
import { DataGrid } from '@mui/x-data-grid'
import { Container, Grid, Paper, Typography, Divider, Button } from '@mui/material'

const columns = [
  { field: 'transactionId', headerName: 'Transaction ID', width: 250 },
  { field: 'amount', headerName: 'Amount', width: 150 },
  { field: 'creditId', headerName: 'Credit ID', width: 250 },
  { field: 'createdAt', headerName: 'Created At', width: 200 },
]

const videoColumns = [
  {
    field: 'name',
    headerName: 'Name',
    width: 400,
  },
  {
    field: 'createdAt',
    headerName: 'Created At',
    width: 200,
  },
]

export default function SingleUserPage() {
  const navigate = useNavigate()
  const { id } = useParams()
  const { loading, error, data } = useQuery(getUser, { variables: { userId: id } })
  const [show, setShow] = React.useState('none')
  const userData = data?.getUser ?? {}
  const [formData, setFormData] = React.useState({
    email: userData.email || '',
    isPremium: userData.isPremium || false,
    role: userData.role || '',
  })


  const [updateExistUser] = useMutation(updateUser)
  const handleUpdateUser = async () => {
    const gpqResponse = await updateExistUser({
      variables: {
        userId: id,
        role: formData.role,
        email: formData.email,
      },
    })
    if (gpqResponse.data) {
      setShow('block')
      setTimeout(() => setShow('none'), 3000)
    }
  }

  const { data: creditData } = useQuery(getUserCreditHistory, { variables: { userId: id } })
  const { data: videoData } = useQuery(dashboardUserVideos, { variables: { userId: id } })
  console.log("data:",data)
  const historyData = creditData?.getUserCreditHistory ?? []
  const sets =  data && data.getUser && data.getUser.sets ? data.getUser.sets : [];

  const handleRowClick = (params) => {
    navigate(`/sets/${params.id}`)
  }

  if (loading) return <ProgressBar />
  if (error) return <p>Error: {error.message}</p>

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Paper elevation={3} sx={{ p: 3 }}>
        <Typography variant="h5" gutterBottom>User Details</Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1"><strong>RevenueCat Customer ID:</strong> {userData.rcUserId}</Typography>
            <Typography variant="body1"><strong>User ID:</strong> {userData.id}</Typography>
            <Typography variant="body1"><strong>User Store:</strong> {userData.store}</Typography>
            <Typography variant="body1"><strong>Total Credit:</strong> {userData.totalCredit.total}</Typography>
            <Typography variant="body1"><strong>Daily Credit:</strong> {userData.totalCredit.dailyCredit}</Typography>
            <Typography variant="body1"><strong>Used Credits:</strong> {userData.usedCreditCount}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              disabled
              fullWidth
              label="Created At"
              variant="filled"
              value={userData.createdAt}
              sx={{ mb: 2 }}
            />
            <TextField
              disabled
              fullWidth
              label="Premium Expiry Date"
              variant="filled"
              value={userData.isPremium}
              sx={{ mb: 2 }}
            />
            {/*           <FormControl fullWidth variant="outlined" sx={{ mb: 2 }}>
              <InputLabel id="role-select-label">Role</InputLabel>
              <Select
                labelId="role-select-label"
                id="role-select"
                name="role"
                value={formData.role}
                onChange={handleChange}
                label="Role"
              >
                <MenuItem value="USER">USER</MenuItem>
                <MenuItem value="ADMIN">ADMIN</MenuItem>
              </Select>
            </FormControl>
            <Button variant="contained" color="primary" onClick={handleUpdateUser}>Update User</Button>*/}
          </Grid>
        </Grid>

        <Divider sx={{ my: 3 }} />

        <Typography variant="h6" gutterBottom>Credit History</Typography>
        <DataGrid
          rows={historyData}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 5,
              },
            },
          }}
        />

        <Divider sx={{ my: 3 }} />

        <Typography variant="h6" gutterBottom>User Sets</Typography>
        <DataGrid
          rowHeight={180}
          rows={sets}
          columns={videoColumns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 5,
              },
            },
          }}
          onRowClick={handleRowClick}
        />
      </Paper>
    </Container>
  )
}
