const AWS = require('aws-sdk')
const { v4: uuidv4 } = require('uuid')

// Configure AWS credentials and region
AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  region: 'eu-central-1',
})

// Create S3 instance
const s3 = new AWS.S3()

export const uploadToS3 = (file, type) => {
  const s3Key = `admin/${uuidv4()}`
  const fileExtension = type === 'img' ? 'jpeg' : type === 'gif' ? 'gif' : 'mp4'
  const params = {
    Bucket: 'flux-uploads',
    Key: `${s3Key}.${fileExtension}`,
    Body: file,
  }

  return new Promise((resolve, reject) => {
    const options = { partSize: 5 * 1024 * 1024, queueSize: 10 }

    s3.upload(params, options, (err, data) => {
      if (err) {
        reject(err)
      } else {
        resolve(`https://fluxai-uploads.univenn.com/${s3Key}.${fileExtension}`, // Include the file extension in the URL
        )
      }
    })
  })
}
