import { gql } from '@apollo/client'

export const getAllUsers = gql`
    query GetAllUsers($pageSize: Int, $page: Int, $rcUserId: String) {
        getAllUsers(pageSize: $pageSize, page: $page, rcUserId: $rcUserId) {
            users {
                id
                createdAt
                updatedAt
                email
                role
                appleSub
                googleSub
                isAgreementChecked
                premiumExpiryDate
                fcmId
                rcUserId
                store
                totalCredit {
                    dailyCredit
                    total
                }
                usedCreditCount
                isPremium
            }
            totalCount
        }
    }
`

export const getUser = gql`
    query GetUser($userId: String!) {
        getUser(userId: $userId) {
            id
            createdAt
            updatedAt
            email
            role
            appleSub
            googleSub
            isAgreementChecked
            premiumExpiryDate
            fcmId
            rcUserId
            store
            totalCredit {
                total
                dailyCredit
            }
            sets {
                id
                name
                createdAt
            }
            usedCreditCount
            isPremium
        }
    }
`

export const getAllvideos = gql`
    query GetAllVideos($pageSize: Int, $page: Int) {
        getAllVideos(pageSize: $pageSize, page: $page) {
            totalCount
            videos {
                id
                createdAt
                updatedAt
                taskId
                model
                url
                status
                prompt
                category
                thumbnailUrl
                title
                motionScore
                style
                progressRatio
                aspectRatio
                upScale
                inputImageUrl
                errorReason
            }
        }
    }
`
export const getVideo = gql`
    query GetVideo($videoId: String!) {
        getVideo(videoId: $videoId) {
            id
            createdAt
            updatedAt
            taskId
            userId
            model
            url
            status
            prompt
            category
            thumbnailUrl
            title
            motionScore
            style
            progressRatio
            aspectRatio
            upScale
            inputImageUrl
            errorReason
        }
    }
`

export const getAllCreditsAdmin = gql`
    query getAllCreditsAdmin {
        getAllCredits {
            amount
            rcEventId
            id
            user {
                id
                email
            }
            date
        }
    }
`
export const getHomePageVideos = gql`
    query GetHomePageVideos {
        getHomePageVideos {
            category
            createdAt
            id
            motionScore
            progressRatio
            prompt
            status
            style
            taskId
            thumbnailUrl
            title
            updatedAt
            url
        }
    }
`
export const getAllStyles = gql`
    query GetAllStylesAdmin {
        getAllStylesAdmin {
            id
            name
            banner
            designType
            rowNumber
            videoUrl
            gifUrl
            createdAt
            updatedAt
        }
    }
`
export const getExplorePage = gql`
    query GetAdminExplores {
        getAdminExplores {
            aspectRatio
            createdAt
            id
            motionScore
            prompt
            style
            thumbnailUrl
            updatedAt
            gif
            category{
                name
                id
            }
            url
        }
    }
`
export const getExploreVideo = gql`
    query GetExploreVideo($exploreId: String!) {
        getExploreVideo(exploreId: $exploreId) {
            id
            url
            prompt
            thumbnailUrl
            motionScore
            style
            createdAt
            updatedAt
            aspectRatio
            gif
            categoryId
            category{
                name
                id
            }
        }
    }
`
export const getUserCreditHistory = gql`
    query GetUserCreditHistory($userId: String!) {
        getUserCreditHistory(userId: $userId) {
            id
            userId
            creditId
            amount
            transactionId
            createdAt
            updatedAt
        }
    }
`
export const dashboardUserVideos = gql`
    query DashboardUserVideos($userId: String!) {
        dashboardUserVideos(userId: $userId) {
            id
            createdAt
            updatedAt
            taskId
            url
            status
            prompt
            category
            thumbnailUrl
            progressRatio
            aspectRatio
            upScale
            title
            motionScore
            style
        }
    }
`
export const dashboardData = gql`
    query DashboardData {
        dashboardData {
            activeSubscriptions
            totalUser
            totalVideo
            modelCount
            dailyVideoCount {
                count
                date
            }
        }
    }
`
export const getCategories = gql`
    query GetCategories {
        getCategories {
            id
            name
            video
            gif
            thumbnail
            description
            prompt
            model
            createdAt
            updatedAt
            type
            isExplore
        }
    }
`
export const getCategory = gql`
    query GetCategory($categoryId: String!) {
        getCategory(categoryId: $categoryId) {
            id
            name
            video
            gif
            thumbnail
            description
            prompt
            model
            createdAt
            updatedAt
            type
            isExplore
        }
    }
`


export const getAllSets = gql`
    query GetAllSetsAdmin {
        getAllSetsAdmin {
            id
            userId
            name
            status
            createdAt
        }
    }
`
export const getSet = gql`
    query GetUserSet($setId: String!) {
        getUserSet(setId: $setId) {
            id
            userId
            createdAt
            updatedAt
            name
            modelId
            prompts
            taskId
            status
            images {
                url
            }
            model {
                version
            }
            user {
                id
            }
        }
    }
`


export const getStyle = gql`
    query GetStyle($styleId: String!) {
        getStyle(styleId: $styleId) {
            id
            name
            banner
            designType
            rowNumber
            videoUrl
            gifUrl
            createdAt
            updatedAt
        }
    }
`
export const getAllPromptsAdmin = gql`
    query GetAllPromptsAdmin {
        getAllPromptsAdmin {
            id
            prompt
            banner
            isVisible
            createdAt
            updatedAt
            styleId
            gender
            aspectRatio
            guidanceScale
            loraScale
            numInferenceSteps
            outputFormat
            outputQuality
            numOfOutPuts
            Style {
                name
            }
        }
    }
`
export const getPrompt = gql`
    query GetPrompt($promptId: String!) {
        getPrompt(promptId: $promptId) {
            id
            prompt
            banner
            isVisible
            createdAt
            updatedAt
            styleId
            gender
            aspectRatio
            guidanceScale
            loraScale
            numInferenceSteps
            outputFormat
            outputQuality
            Style{
                name
                id
            }
            numOfOutPuts
        }
    }
`
export const getModels = gql`
    query GetAllModelsAdmin {
        getAllModelsAdmin {
            id
            taskId
            version
            name
            image
            instanceData
            status
            createdAt
            updatedAt
            userId
            predict_time
            loraSafetensors
            triggerWord
        }
    }
`
export const getModelData = gql`
    query GetModel($modelId: String!) {
        getModel(modelId: $modelId) {
            id
            taskId
            version
            name
            image
            instanceData
            status
            createdAt
            updatedAt
            userId
            predict_time
            loraSafetensors
            triggerWord
        }
    }
`
